import React, { useState } from "react";
import Mark from "./Mark";
import "./Auth_style.css";
import "../../assests/styles/auth.scss";
import { Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../layout";
import axios from "axios";
import { Form, Input } from "antd";
// import OtpForm from "react-otp-ui";  
import OtpInput from "react-otp-input";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { apiUrl } from "../../config";

function Forgotpwd(props) {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [Spinner, setSpinner] = useState(false);
  const [otp, setOtp] = useState("");
  const isuser = useSelector((state) => state.UserAuth.UserInfo);
  const [Email, setEmail] = useState("");
console.log("page",page)
  const onFinish = async (values) => {
    try {
      setSpinner(true);

      if (page === 3) {
        let res = await axios.post(
          `${apiUrl}/api/v1/forgotPassword/${Email}`,
          {
            newPassword: values.password,
          }
        );
        if (res.data.success == true) {
          
          toast.success(res.data.msg);
          navigate("/login");

          setSpinner(false);
        } else {
          console.log(res)
          toast.error(res.data.msg);
          
          setSpinner(false);
        }
      } else {
        let res = await axios.post(`${apiUrl}/api/v1/sendOTPForgot`, {
          email: values.email,
        });
        if (res.data.success == true) {
          
          toast.success(res.data.msg);
          setPage(2);
          setEmail(values.email);
          setSpinner(false);
        } else {

          toast.error(res.data);
          setSpinner(false);
        }
      }
    } catch (error) {
      setSpinner(false);
    }
  };
  const validatePassword = (rule, value, callback) => {
 
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!value || passwordRegex.test(value)) {
      callback();
    } else {
      callback("Password must be at least 8 characters & includes at least one letter, number & special character!");
    }
  };
  const onFinishFailed = (errorInfo) => {};
  const ForgotEmilSend = async () => {
    try {
    } catch (error) {}
  };

  const handleOnChange = (data) => {
    setOtp(data.otpValue);
  };

  const Verify_OTP = async () => {
    try {
      setSpinner(true);

      let res = await axios.post(`${apiUrl}/api/v1/VarifyForgotOTP`, {
        email: Email,
        code: otp,
      });
      if (res.data.success == true) {
        toast.success(res.data.msg);
        setPage(3);

        setSpinner(false);
      } else {
        toast.error(res.data.msg);
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
    }
  };

  return (
    <>
      <div className="auth">
        <div className="forgotpwd">
          <div className="forgotpwd-mark-div">
            <Mark />
          </div>
          <div className="forgotpwd-info-div">
            <div className="forgotpwd-info-div-x-space"></div>
            <div className="forgotpwd-info-main-div">
              <div className="forgotpwd-info-main-top-space"></div>
              <span className="forgotpwd-title auth-title">
                Forgot Password ({page ? page : 3}/3)
              </span>
              <div className="forgotpwd-item-space-half" />
              <span className="forgotpwd-text auth-text">
                {page === 1
                  ? "Let’s see what we can do!"
                  : page === 2
                  ? "We have sent you a confirmation email"
                  : "New password"}
              </span>
              <div className="forgotpwd-item-space" />
              {page === 1 ? (
                <>
                  <Form
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email"
                        className="auth-input login-input"
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        className="auth-button forgotpwd-button"
                        htmlType="submit"
                        // onClick={() => navigate("/")}
                      >
                        {Spinner == true ? (
                          <>
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </>
                        ) : (
                          "Next"
                        )}
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              ) : // <Input
              //   placeholder="Email or Username"
              //   className="auth-input forgotpwd-input "
              // />
              page === 2 ? (
                <>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    secureInput={false}
                    renderInput={(props) => <input {...props} />}
                    inputStyle="otp_class"
                  />

                  <Button
                    className="auth-button forgotpwd-button mt-5"
                    htmlType="submit"
                    onClick={() => Verify_OTP()}
                  >
                    {Spinner == true ? (
                      <>
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </>
                    ) : (
                      "Next"
                    )}
                  </Button>
                </>
              ) : (
                <>
                  <Form
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          validator: validatePassword,
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Password"
                        className="signup_password auth-input signup-input p-2"
                      />
                    </Form.Item>

                    <Form.Item
                      name="Cpassword"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Confirm Password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "Confirm Password that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirm Password"
                        className="signup_password auth-input signup-input p-2"
                        type="password"
                      />
                    </Form.Item>
                    <Form.Item className="d-flex justify-content-center">
                      <Button
                        className="auth-button p-4"
                        // onClick={() => setSignupStep(1)}
                        htmlType="submit"
                        style={{ width: "12rem", marginLeft: "-2rem" }}
                      >
                        {Spinner == true ? (
                          <>
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </>
                        ) : (
                          "Reset Password"
                        )}
                      </Button>
                    </Form.Item>
                  </Form>
                  {/* <Input
                    placeholder="Enter Password"
                    className="auth-input forgotpwd-input "
                    type="password"
                  />
                  <div className="forgotpwd-item-space" />
                  <Input
                    placeholder="Verify Password"
                    className="auth-input forgotpwd-input"
                    type="password"
                  /> */}
                </>
              )}
              <div className="forgotpwd-item-space-half" />
              {/* <Button
                className="auth-button forgotpwd-button"
                onClick={() => {
                  setPage(page + 1);

                }}
              >
                {page !== 3 ? "Next" : "Submit"}
              </Button> */}
              <div className="forgotpwd-item-space-half" />
              <span
                className="forgotpwd-account-question auth-text"
                style={{ fontSize: "16px" }}
              >
                Need an account?
                <Link
                  className="auth-text-black ms-2 text-bold"
                  to="/signup"
                  style={{ fontSize: "18px" }}
                >
                  Sign Up{" "}
                </Link>
              </span>
              <div className="forgotpwd-item-space-half" />
              <span
                className="forgotpwd-account-question auth-text"
                style={{ fontSize: "16px" }}
              >
                Already have an account?
                <Link
                  className="auth-text-black ms-2"
                  to="/login"
                  style={{ fontSize: "18px" }}
                >
                  Log In{" "}
                </Link>
              </span>
              <div className="forgotpwd-info-main-bottom-space"></div>
            </div>
            <div className="forgotpwd-info-div-x-space"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Forgotpwd;

