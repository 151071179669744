import * as userManagementServices from "./userServices";
import { userManagementSlice } from "./userManagementSlice";
const actions = userManagementSlice.actions;

export const createPermissions = (permission, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall("action"));

  try {
    const res = await userManagementServices.createPermissions(permission);

    dispatch(actions.stopCall("action"));
  } catch (error) {
    dispatch(actions.stopCall("action"));
    onError();
  }
};

export const getAllPermissions = () => async (dispatch) => {
  dispatch(actions.startCall("listLoading"));
  try {
    const res = await userManagementServices.getAllPermissions();
    dispatch(actions.getAllPermissions(res));

    dispatch(actions.stopCall("listLoading"));
  } catch (error) {
    dispatch(actions.stopCall("listLoading"));
  }
};

export const getAllRoles = () => async (dispatch) => {
  dispatch(actions.startCall("listLoading"));
  try {
    const res = await userManagementServices.getAllRoles();
    dispatch(actions.getAllRoles(res));
  } catch (error) {
    dispatch(actions.stopCall("listLoading"));
  }
};

export const getRoleById = (roleId) => async (dispatch) => {
  dispatch(actions.startCall("action"));
  try {
    const res = await userManagementServices.getRoleById(roleId);
    dispatch(actions.setSelectedRole(res?.data?.data));
  } catch (error) {
    dispatch(actions.stopCall("action"));
  }
};

export const deleteRoleById = (roleId, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall("action"));
  try {
    const res = await userManagementServices.deleteRole(roleId);
    dispatch(actions.stopCall("action"));
    onSuccess(res);
  } catch (error) {
    dispatch(actions.stopCall("action"));
    
  }
};

export const getPermissionById = (permissionId) => async (dispatch) => {
  dispatch(actions.startCall("action"));
  try {
    const res = await userManagementServices.getPermissionById(permissionId);
    dispatch(actions.setSelectedPermission(res?.data?.data));
  } catch (error) {
    dispatch(actions.stopCall("action"));
  }
};

export const deletePermissionById = (permissionId, onSuccess) => async (dispatch) => {
  dispatch(actions.startCall("action"));
  try {
    const res = await userManagementServices.deletePermission(permissionId);
    dispatch(actions.stopCall("action"));
    onSuccess(res);
  } catch (error) {
    dispatch(actions.stopCall("action"));
    
  }
};

export const updateUser = (id, formData,onSuccess ) => async (dispatch) => {
  try {
    const res = await userManagementServices.updateUser(id, formData);
    onSuccess(res)
  } catch (error) {
  
  }
};

export const updatePermissionById = (permissionId, formData, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall("action"));
  try {
    const res = await userManagementServices.updatePermissionById(permissionId, formData);
    dispatch(actions.setSelectedPermission({ permission: null }));
    onSuccess(res);
  } catch (error) {
    dispatch(actions.stopCall("action"));
    onError(error);
  }
};

export const updateRoleById = (roleId, formData, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall("action"));
  try {
    const res = await userManagementServices.updateRoleById(roleId, formData);
    dispatch(actions.setSelectedRole({ role: null }));
    onSuccess(res);
  } catch (error) {
    dispatch(actions.stopCall("action"));
    onError(error);
  }
};

export const addUser = (formData, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall("action"));
  try {
    const res = await userManagementServices.addUser(formData);
    dispatch(actions.stopCall("action"));
    onSuccess(res);
  } catch (error) {
    dispatch(actions.stopCall("action"));
    onError(error);
  }
};

export const addPermissions = (formData, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall("action"));
  try {
    const res = await userManagementServices.addPermissions(formData);
    dispatch(actions.stopCall("action"));
    onSuccess(res);
  } catch (error) {
    dispatch(actions.stopCall("action"));
    onError(error);
  }
};

export const addRoles = (formData, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall("action"));
  try {
    const res = await userManagementServices.addRoles(formData);
    dispatch(actions.stopCall("action"));
    onSuccess(res);
  } catch (error) {
    dispatch(actions.stopCall("action"));
    onError(error);
  }
};

export const getUserById = (id) => async (dispatch) => {
  dispatch(actions.startCall("action"));
  try {
    const res = await userManagementServices.getUserById(id);
    dispatch(actions.setSelectedUser(res?.data?.data));
  } catch (error) {
    dispatch(actions.stopCall("action"));
  }
};

export const deleteUserById = (id, onSuccess) => async (dispatch) => {
  dispatch(actions.startCall("action"));
  try {
    const res = await userManagementServices.deleteUserById(id);
    dispatch(actions.stopCall("action"));
    onSuccess(res);
  } catch (error) {
    dispatch(actions.stopCall("action"));
    
  }
};

export const getAllUsers = (params) => async (dispatch) => {
  dispatch(actions.startCall("listLoading"));
  try {
    const res = await userManagementServices.getAllUsers(params);
    dispatch(actions.saveUsers(res));
  } catch (err) {
    dispatch(actions.stopCall("listLoading"));

  }
};

export const getAllUsersWithoutPagination = () => async (dispatch) => {
  dispatch(actions.startCall("listLoading"));

  try {
    const res = await userManagementServices.getAllUsersWithoutPagination();
    dispatch(actions.saveUsers(res));
  } catch (err) {
    dispatch(actions.stopCall("listLoading"));

  }
};