// import { getAllUsersWithoutPagination, updateUser } from "./_redux/userActions";
// import { useDispatch, useSelector, shallowEqual } from "react-redux";
// import { useState, useEffect } from "react";

// import { BiEdit } from "react-icons/bi";
// import { ConfirmModal } from "./confirmModal/ConfirmModal";
// import ComponentLoader from './../../../components/shared/loader/ComponentLoader';

// const UsersDetail = () => {
//   const { entities } = useSelector(
//     (state) => state?.userManagementSlice,
//     shallowEqual
//   );
//   const dispatch = useDispatch();

//   const [isLoading, setIsLoading] = useState(false);
//   const [shouldReload, setShouldReload] = useState(false);

//   useEffect(() => {
//     dispatch(getAllUsersWithoutPagination());
//     return () => {};
//   }, [shouldReload]);

//   return (
    
//     <div className="w-full sm:rounded-md overflow-hidden p-6 mt-8 bg-gray-50 shadow-lg mt-[5%]">
//       {isLoading && <ComponentLoader />}
//       <div className="flex flex-col border rounded-lg shadow-lg border-gray-300 bg-white">
//         <div className="overflow-x-auto">
//           <div className="py-4 align-middle">
//             <div className="overflow-hidden md:rounded-lg">
//               <table className="min-w-full divide-y divide-gray-300 table-auto">
//                 <thead className="bg-gray-100 border-b-2 border-gray-300">
//                   <tr>
//                     <th
//                       scope="col"
//                       className="py-3.5 pl-6 text-left text-lg font-semibold text-gray-900"
//                     >
//                       Name
//                     </th>
//                     <th
//                       scope="col"
//                       className="py-3.5 text-left text-lg font-semibold text-gray-900"
//                     >
//                       Email
//                     </th>
//                     <th
//                       scope="col"
//                       className="py-3.5 text-left text-lg font-semibold text-gray-900"
//                     >
//                       User Type
//                     </th>
//                     <th
//                       scope="col"
//                       className="py-3.5 pr-6 text-left text-lg font-semibold text-gray-900"
//                     >
//                       Actions
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody className="divide-y divide-gray-200 bg-white">
//                   {entities &&
//                     entities.map((person) => (
//                       <tr key={person?._id} className="hover:bg-gray-50">
//                         <td className="whitespace-nowrap py-4 pl-6 text-md text-gray-800">
//                           {person?.UserName}
//                         </td>
//                         <td className="whitespace-nowrap py-4 text-md text-gray-800">
//                           <p className="truncate w-48">{person.email}</p>
//                         </td>
//                         <td className="whitespace-nowrap py-4 text-md text-gray-800">
//                           {person?.isPrevillagedUser ? "Privileged User" : "Basic User"}
//                         </td>
//                         <td className="whitespace-nowrap py-4 pr-6 text-right">
//                           <button
//                             className={`${
//                               person?.role?.slug === "Super_Admin_Slug"
//                                 ? "cursor-not-allowed text-gray-500"
//                                 : "text-black text-md hover:scale-110 hover:text-blue-600"
//                             }`}
//                             onClick={() => {
//                               const invert = !person?.isPrevillagedUser;
//                               setIsLoading(true);
//                               dispatch(
//                                 updateUser(person?._id, { isPrevillagedUser: invert }, () => {
//                                   setIsLoading(false);
//                                   setShouldReload((prev) => !prev);
//                                 })
//                               );
//                             }}
//                           >
//                             <BiEdit className="w-5 h-5" title="Grant/Revoke privilege" />
//                           </button>
//                         </td>
//                       </tr>
//                     ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UsersDetail;

import { getAllUsersWithoutPagination, updateUser } from "./_redux/userActions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useState, useEffect } from "react";
import { BiEdit } from "react-icons/bi";
import ComponentLoader from './../../../components/shared/loader/ComponentLoader';

const UsersDetail = () => {
  const { entities } = useSelector(
    (state) => state?.userManagementSlice,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
    dispatch(getAllUsersWithoutPagination());
  }, [shouldReload, dispatch]);

  const handleUpdateUser = (userId, isPrevillagedUser) => {
    setIsLoading(true);
    dispatch(
      updateUser(userId, { isPrevillagedUser }, () => {
        setIsLoading(false);
        setShouldReload((prev) => !prev);
      })
    );
  };

  return (
    <div className="w-full px-4 sm:px-6 py-8 mt-10 bg-gray-50 shadow-lg sm:rounded-lg">
      {isLoading && <ComponentLoader />}
      <div className="flex flex-col border  mt-32 rounded-lg shadow-md border-gray-200 bg-white">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 table-auto">
            <thead className="bg-gray-100">
              <tr>
                <th className="py-4 px-6 text-left text-sm sm:text-base font-bold text-gray-900 uppercase tracking-wide">Name</th>
                <th className="py-4 px-6 text-left text-sm sm:text-base font-bold text-gray-900 uppercase tracking-wide">Email</th>
                <th className="py-4 px-6 text-left text-sm sm:text-base font-bold text-gray-900 uppercase tracking-wide">User Type</th>
                <th className="py-4 text-center  text-sm sm:text-base font-bold text-gray-900 uppercase tracking-wide">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {entities?.map((person, index) => (
                <tr key={person?._id} className={`hover:bg-gray-50 ${index % 2 ? 'bg-gray-50' : 'bg-white'}`}>
                  <td className="py-4  text-left px-2 text-sm  sm:text-base text-gray-800 whitespace-nowrap">
                    {person?.UserName}
                  </td>
                  <td className="py-4  text-left px-2  text-sm sm:text-base text-gray-800 whitespace-nowrap">
                    <p className="truncate ">{person.email}</p>
                  </td>
                  <td className="py-4  text-left px-2  text-sm sm:text-base text-gray-800">
                    {person?.isPrevillagedUser ? "Privileged User" : "Basic User"}
                  </td>
                  <td className="py-4 text-center px-2  ">
                    <button
                      className={`${
                        person?.role?.slug === "Super_Admin_Slug"
                          ? "cursor-not-allowed text-gray-400"
                          : "text-black hover:text-blue-600 transform hover:scale-105 transition duration-150 ease-in-out"
                      }`}
                      onClick={() => {
                        if (person?.role?.slug !== "Super_Admin_Slug") {
                          handleUpdateUser(person?._id, !person?.isPrevillagedUser);
                        }
                      }}
                      disabled={person?.role?.slug === "Super_Admin_Slug"}
                    >
                      <BiEdit className="w-5 h-5" title="Grant/Revoke privilege" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UsersDetail;
