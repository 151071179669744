// import { Check } from "@mui/icons-material";
// import { Skeleton, notification } from "antd";
// import Aos from "aos";
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import {
//   useCollapse as expand1,
//   useCollapse as expand2,
//   useCollapse as expand3,
//   useCollapse,
// } from "react-collapsed";
// import { ImBlocked } from "react-icons/im";
// import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import chevronRight from "../../../assests/image/chevron-right-square.svg";
// import shareIcon from "../../../assests/image/shareIcon.png";
// import { apiUrl } from "../../../config";
// import { copyToClipboard, currentDate } from "../../../helper";
// import { stdCategories } from "../ArticlesBoard/ArticlesBoard";
// import User_DashBoard_Header from "../User_DashBoard_Header/User_DashBoard_Header";
// import "./Deep_Dive.css";

// const predCategories = ["Most likely", "Possible", "Unlikely"];

// export default function DeepDiveBoard({
//   artical,
//   back = "/",
//   date = currentDate(),
//   timeframe = "day",
//   category = stdCategories[0],
//   primary = "",
//   isShare = false,
//   defaultActive = -1,
// }) {
//   const [Predicted_error, setPredicted_error] = useState(false);
//   const [Predicted_check, setPredicted_check] = useState(false);
//   const [Predicted_text, setPredicted_text] = useState(predCategories[0]);
//   const [EnablePredictions, setEnablePredictions] = useState(true);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [active, setActive] = useState(defaultActive);
//   const [groupingArticle, setGroupingArticle] = useState(null);
//   const copyToCliboard = () => {
//     copyToClipboard(
//       `${origin}/share/${btoa(
//         JSON.stringify({
//           type: "deepdive",
//           date,
//           timeframe,
//           category: category,
//           primary,
//         })
//       )}`
//     );
//     notification.open({
//       message: "Link Copied",
//       placement: "top",
//       className: "notification",
//       closeIcon: <Check />,
//       style: {
//         width: "200px",
//       },
//     });
//   };
//   useEffect(() => {
//     if (artical) {
//       let articleDescription = "";

//       if (artical?.primaryArtical?.article.length < 500) {
//         articleDescription = artical.primaryArtical.article;
//       } else if (artical?.secondaryArticals?.[0]?.article) {
//         articleDescription = artical.secondaryArticals[0].article;
//       } else {
//         const trimmedArticle = artical.primaryArtical.article
//           .split(" ")
//           .slice(0, 500)
//           .join(" ");
//         articleDescription = `${trimmedArticle}...`;
//       }

//       const deepResearchData =
//         artical?.primaryArtical?.analyzer_category?.summary &&
//         artical?.primaryArtical?.analyzer_category?.title
//           ? {
//               "1 day timeframe": {
//                 [Predicted_text]: {
//                   Description: articleDescription,
//                   Explanation: artical.primaryArtical.analyzer_category.summary,
//                 },
//               },
//             }
//           : null;

//       setGroupingArticle({
//         topic: artical?.title[0],
//         deep_research: deepResearchData,
//         summaries: [
//           {
//             title: artical.primaryArtical.analyzer_category.title,
//             summary: artical.primaryArtical.analyzer_category.summary,
//             link: artical.primaryArtical.link,
//           },
//         ],
//       });
//     } else {
//       fetch_groupingArticle();
//     }
//   }, [artical, Predicted_text]);

//   console.log(Predicted_text);
//   const fetch_groupingArticle = async () => {
//     try {
//       const grouping_article = await axios.post(
//         apiUrl + "/api/v1/groupingArticle",
//         {
//           category: category === "Overall" ? "At a Glance" : category,
//           primary: primary,
//           timeframe: timeframe,
//           datetime: date,
//         }
//       );

//       setGroupingArticle(grouping_article.data?.data);
//     } catch (e) {}
//   };

//   // useEffect(() => {
//   // setGroupingArticle(groupingArticle)
//   // fetch_groupingArticle();
//   // }, []);
//   // setInterval(() => {

//   //   fetch_groupingArticle();
//   // }, 2000);
//   const showModal = () => {
//     setIsModalOpen(true);
//   };
//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const isuser = useSelector((state) => state.UserAuth.isAuth);
//   const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

//   const {
//     getCollapseProps: getCollapseProps5,
//     getToggleProps: getToggleProps5,
//     isExpanded: isExpanded5,
//   } = expand3({
//     defaultExpanded: true,
//   });

//   const {
//     getCollapseProps: getCollapseProps2,
//     getToggleProps: getToggleProps2,
//     isExpanded: isExpanded2,
//   } = expand1();
//   const {
//     getCollapseProps: getCollapseProps4,
//     getToggleProps: getToggleProps4,
//     isExpanded: isExpanded4,
//   } = expand3();
//   const {
//     getCollapseProps: getCollapseProps3,
//     getToggleProps: getToggleProps3,
//     isExpanded: isExpanded3,
//   } = expand2();
//   useEffect(() => {
//     Aos.init();
//   }, []);

//   return (
//     <>
//       <User_DashBoard_Header />
//       <div className="Deep_Dive" style={{ marginTop: "50px" }}>
//         <div className="container">
//           <div className="d-flex justify-content-between align-items-center main_div_deep_dive_heading">
//             <div className="first_heading_deep">
//               <div className="Text_Top_Deep"></div>
//               <div className="text_on_Deep_bg">
//                 <h6>
//                   <Link
//                     to={back || "/"}
//                     onClick={() => {
//                       setGroupingArticle(null);
//                     }}
//                   >
//                     <img
//                       src={chevronRight}
//                       alt="chevronRight"
//                       className="me-2 "
//                     />
//                   </Link>
//                   {groupingArticle != null ? (
//                     <>{groupingArticle?.topic} </>
//                   ) : (
//                     <>
//                       <Skeleton.Input />
//                     </>
//                   )}

//                   <p className="text_deep">Deep Dive</p>
//                 </h6>
//               </div>
//             </div>
//             <img
//               src={shareIcon}
//               alt="shareIcon"
//               width="2%"
//               height="1%"
//               className="mt-5 shareIcon"
//               onClick={() => copyToCliboard()}
//               style={{ cursor: "pointer" }}
//             />
//           </div>

//           <div className="main_Deep_dive">
//             <div className="row">
//               <div className="col-lg-3">
//                 <h1
//                   className="lister_heading"
//                   style={{
//                     cursor: "pointer",
//                   }}
//                   onClick={() => {
//                     setActive(-1);
//                   }}
//                 >
//                   Analysis
//                 </h1>
//                 <ul className="deep_dive_list">
//                   {groupingArticle && groupingArticle.summaries ? (
//                     <>
//                       {groupingArticle.summaries &&
//                         groupingArticle?.summaries
//                           .reduce(
//                             (acc, curr) =>
//                               acc.map((v) => v.title).includes(curr.title)
//                                 ? acc
//                                 : [...acc, curr],
//                             []
//                           )
//                           .map((summary, index) => {
//                             return (
//                               <li
//                                 className={
//                                   active === index ? "Active_Item" : ""
//                                 }
//                                 onClick={() => setActive(index)}
//                               >
//                                 {summary.title}
//                               </li>
//                             );
//                           })}
//                     </>
//                   ) : (
//                     <>
//                       <Skeleton paragraph={{ rows: 4 }} />
//                     </>
//                   )}
//                 </ul>
//               </div>
//               <div className="col-lg-9">
//                 <div className="deep_dive_second_col">
//                   {active === -1 ? (
//                     <ul>
//                       {groupingArticle?.deep_research ? (
//                         Object.keys(groupingArticle.deep_research).map(
//                           (heading) => (
//                             <>
//                               <h6
//                                 className="fs-5 fw-bold"
//                                 style={{ color: "#6b6b6b" }}
//                               >
//                                 {heading}
//                               </h6>
//                               <p className="articalText">
//                                 {groupingArticle.deep_research[heading][
//                                   Predicted_text
//                                 ]?.Description ?? "No description available."}
//                               </p>
//                             </>
//                           )
//                         )
//                       ) : (
//                         <Skeleton paragraph={{ rows: 8 }} />
//                       )}
//                     </ul>
//                   ) : (
//                     <>
//                       <h6 className="fs-5 fw-bold" style={{ color: "#6b6b6b" }}>
//                         {groupingArticle?.summaries[active]?.title ??
//                           "Title unavailable."}
//                       </h6>
//                       <p className="articalText">
//                         {groupingArticle?.summaries[active]?.summary ??
//                           "Summary unavailable."}
//                       </p>
//                       <Link
//                         to={groupingArticle?.summaries[active]?.link ?? "#"}
//                         target="_blank"
//                       >
//                         {groupingArticle?.summaries[active]?.link ??
//                           "Link unavailable."}
//                       </Link>
//                     </>
//                   )}
//                 </div>

//                 <div className="landing">
//                   <div className="content " id="At_a_Glance">
//                     <div className="content-body" style={{ padding: "0px" }}>
//                       <div className="content-right-sider">
//                         {EnablePredictions === true ? (
//                           <>
//                             <div className="Predictions mt-5">
//                               <span>Predicted Scenarios:</span>
//                               <br />
//                               <span className="disclaimer">
//                                 *<Link to="/Legal_Docs">Disclaimer</Link>* For
//                                 educational purposes only
//                               </span>
//                             </div>
//                             <div className="Predictions">
//                               <div className="boxes1">
//                                 <div
//                                   className="box1"
//                                   style={{
//                                     backgroundColor:
//                                       isuser == true || Predicted_error == false
//                                         ? ""
//                                         : "#FFE6E6",
//                                     border:
//                                       isuser == true || Predicted_error == false
//                                         ? ""
//                                         : "1px solid rgba(232, 3, 112, 0.8)",
//                                   }}
//                                 >
//                                   <h3 className="headerbox text-center pb-3">
//                                     {Predicted_text}
//                                   </h3>
//                                   <ul className="boxInner">
//                                     <li className="pb-4">
//                                       {groupingArticle ? (
//                                         Object.keys(
//                                           groupingArticle.deep_research
//                                         ).map((heading) => (
//                                           <>
//                                             {groupingArticle?.deep_research[
//                                               heading
//                                             ][Predicted_text]?.Description ??
//                                               "No description available."}
//                                           </>
//                                         ))
//                                       ) : (
//                                         <>
//                                           <Skeleton paragraph={{ rows: 4 }} />
//                                         </>
//                                       )}
//                                     </li>

//                                     <section {...getCollapseProps5()}>
//                                       <li className="pb-3">
//                                         {groupingArticle &&
//                                         groupingArticle?.deep_research ? (
//                                           <>
//                                             {
//                                               groupingArticle?.deep_research[
//                                                 "1 day timeframe"
//                                               ][Predicted_text]?.Explanation
//                                             }
//                                           </>
//                                         ) : (
//                                           <>
//                                             <Skeleton paragraph={{ rows: 4 }} />
//                                           </>
//                                         )}
//                                       </li>
//                                     </section>
//                                   </ul>
//                                   <div style={{ textAlign: "center" }}>
//                                     <span
//                                       {...getToggleProps5()}
//                                       className="expand"
//                                     >
//                                       {isExpanded5 ? "Collapse-" : "Expand+"}
//                                     </span>
//                                   </div>
//                                 </div>
//                               </div>
//                               <div className="mt-3 d-flex justify-content-start">
//                                 {predCategories
//                                   .filter((pca) => pca !== Predicted_text)
//                                   .map((pca, index) => {
//                                     let classNm = "";
//                                     if (index == 0) {
//                                       classNm = "me-2";
//                                     }
//                                     return (
//                                       <button
//                                         className={`Possible_btn ${classNm}`}
//                                         onClick={() => setPredicted_text(pca)}
//                                       >
//                                         {pca}
//                                       </button>
//                                     );
//                                   })}
//                               </div>
//                             </div>

//                             {isuser == true || Predicted_error == false ? (
//                               <></>
//                             ) : (
//                               <>
//                                 <Link
//                                   to="/signup"
//                                   className="link_dec"
//                                   onClick={() => window.scrollTo(0, 0)}
//                                 >
//                                   <div
//                                     className="d-flex justify-content-center  align-items-center mt-3"
//                                     data-aos="fade-down"
//                                     data-aos-easing="linear"
//                                     data-aos-duration="500"
//                                   >
//                                     <div className="d-flex justify-content-center  align-items-center Show_Err0r_ISAuth ">
//                                       <ImBlocked className="Auth_icon" />
//                                       <p className="Auth_p">
//                                         Create a FREE Account to access this
//                                         feature!
//                                       </p>
//                                     </div>
//                                   </div>
//                                 </Link>
//                               </>
//                             )}
//                           </>
//                         ) : (
//                           ""
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

import {
  Input,
  Button,
  Upload,
  List,
  Avatar,
  Skeleton,
  notification,
  Form,
} from "antd";

import Aos from "aos";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  useCollapse as expand1,
  useCollapse as expand2,
  useCollapse as expand3,
  useCollapse,
} from "react-collapsed";
import chevronRight from "../../../assests/image/chevron-right-square.svg";
import shareIcon from "../../../assests/image/shareIcon.png";

import { ImBlocked } from "react-icons/im";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Check } from "@mui/icons-material";

import { copyToClipboard, currentDate } from "../../../helper";
import { stdCategories } from "../ArticlesBoard/ArticlesBoard";
import User_DashBoard_Header from "../User_DashBoard_Header/User_DashBoard_Header";
import "./Deep_Dive.css";
import { apiUrl } from './../../../config';
import { PieChart } from "@mui/x-charts";
import { Box, useMediaQuery } from "@mui/material";
import { Header } from "../../layout";

const predCategories = ["Most likely", "Possible", "Unlikely"];
const { TextArea } = Input;

export default function DeepDiveBoard({
  artical,
  back = "/",
  date = currentDate(),
  timeframe = "day",
  category = stdCategories[0],
  primary = "",
  isShare = false,
  defaultActive = -1,
}) {
  const {UserInfo}=useSelector(state=>state.UserAuth, shallowEqual)

  const [Predicted_error, setPredicted_error] = useState(false);
  const [Predicted_check, setPredicted_check] = useState(false);
  const [Predicted_text, setPredicted_text] = useState(predCategories[0]);
  const [EnablePredictions, setEnablePredictions] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [active, setActive] = useState(defaultActive);
  const [groupingArticle, setGroupingArticle] = useState(null);
  const [comments, setComments] = useState([]); // State for comments
const [shouldReload, setShouldReload]=useState(false)
  const [image, setImage] = useState(null); // State for image upload

  const fetchArticles = async () => {
    const articleId = artical?.primaryArtical?._id;
    if (!apiUrl || !articleId) {
      console.error('Invalid API URL or article ID');
   
      return;
    }
    return await axios.get(`${apiUrl}/api/v1/getComments/${articleId}`);
  };
useEffect(() => {
  const fetchComments = async () => {
    try {
      const commentsInArticle = await fetchArticles();
 
      if (commentsInArticle?.data?.data?.length > 0) {
        setComments(commentsInArticle?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };
  fetchComments();
}, [artical.primaryArtical?._id,shouldReload]); // Replace `comments` with the appropriate dependency

  const [score, setScore] = useState(null);
  const copyToCliboard = () => {
    copyToClipboard(
      `${origin}/share/${btoa(
        JSON.stringify({
          type: "deepdive",
          date,
          timeframe,
          category: category,
          primary,
        })
      )}`
    );
    notification.open({
      message: "Link Copied",
      placement: "top",
      className: "notification",
      closeIcon: <Check />,
      style: {
        width: "200px",
      },
    });
  };

  const matches = useMediaQuery("(min-width:1000px)");
  const ResponsiveMatches = useMediaQuery("(min-width:500px)");
  useEffect(() => {
    if (
      artical.primaryArtical ||
      (artical.secondaryArticals && artical.secondaryArticals.length > 0)
    ) {
      let articleDescription = "";
      if (artical?.primaryArtical?.score) {
        setScore(artical.primaryArtical.score);
      } else if (artical?.secondaryArticals[0]?.score) {
        setScore(artical.secondaryArticals[0].score);
      }
      if (artical?.primaryArtical?.article.length < 500) {
        articleDescription = artical.primaryArtical.article;
      } else if (artical?.secondaryArticals?.[0]?.article) {
        articleDescription = artical.secondaryArticals[0].article;
      } else {
        const trimmedArticle = artical?.primaryArtical?.article
          .split(" ")
          .slice(0, 500)
          .join(" ");
        articleDescription = `${trimmedArticle}...`;
      }
      const deepResearchData =
        artical?.primaryArtical?.analyzer_category?.summary &&
        artical?.primaryArtical?.analyzer_category?.title
          ? {
              "1 day timeframe": {
                [Predicted_text]: {
                  Description: articleDescription,
                  Explanation:
                    artical?.primaryArtical?.analyzer_category?.summary,
                },
              },
            }
          : {
              "1 day timeframe": {
                [Predicted_text]: {
                  Description: articleDescription,
                  Explanation: artical?.primaryArtical?.title,
                },
              },
            };
      setGroupingArticle({
        topic: artical?.title[0],
        deep_research: deepResearchData,
        summaries: [
          {
            title: artical?.primaryArtical
              ? artical.primaryArtical.analyzer_category?.title === ""
                ? artical.primaryArtical.title
                : artical.primaryArtical.analyzer_category?.title
              : artical?.secondaryArticals[0]?.analyzer_category?.title ||
                "Default Title",

            summary: artical?.primaryArtical
              ? artical.primaryArtical.analyzer_category?.summary === ""
                ? "N/A"
                : artical.primaryArtical.analyzer_category?.summary
              : artical?.secondaryArticals[0]?.analyzer_category?.summary ||
                "N/A",

            link: artical?.primaryArtical
              ? artical.primaryArtical.link
              : artical?.secondaryArticals[0]?.link || "#",
          },
        ],
      });
    } else {
      setGroupingArticle({
        topic: artical?.title[0],
        deep_research: {
          "1 day timeframe": {
            [Predicted_text]: {
              Description: "No description available.",
              Explanation:
                "No explanation available. The details about the event or research are not provided.",
            },
          },
        },
        summaries: [
          {
            title: "No Title Available",
            summary:
              "No summary available. Details about the event or topic are not provided.",
            link: "#",
          },
        ],
      });
      // fetch_groupingArticle();
    }
  }, [artical, Predicted_text]);

  const fetch_groupingArticle = async () => {
    try {
      const grouping_article = await axios.post(apiUrl + "/api/v1/groupingArticle", {
        category: category === "Overall" ? "At a Glance" : category,
        primary: primary,
        timeframe: timeframe,
        datetime: date,
      });

      setGroupingArticle(grouping_article.data?.data);
    } catch (e) {}
  };

  // Function to handle the comment submission
  const handleCommentSubmit = async (values) => {
   
    try {      
     const data={
      articleId:artical.primaryArtical?._id,
      comment:values?.comment,
      commentedBy:UserInfo?.UserName
     }

      // Make API call to your backend to submit comment and image
      const response = await axios.post(`${apiUrl}/api/v1/addComments`, data);


      notification.success({
        message: "Comment submitted successfully!",
      });
if(response){
 
  setShouldReload((pre)=>!pre)
 }
      // Reset form and image state
      setImage(null);

    } catch (error) {
      notification.error({
        message: "Failed to submit the comment. Please try again.",
      });
    }
  };

  const handleImageUpload = (info) => {
    if (info.file.status === "done") {
      setImage(info.file.originFileObj); // Store the image file for uploading
    }
  };

  useEffect(() => {
    Aos.init();
  }, []);

  const isuser = useSelector((state) => state.UserAuth.isAuth);
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const {
    getCollapseProps: getCollapseProps5,
    getToggleProps: getToggleProps5,
    isExpanded: isExpanded5,
  } = expand3({
    defaultExpanded: true,
  });

  const {
    getCollapseProps: getCollapseProps2,
    getToggleProps: getToggleProps2,
    isExpanded: isExpanded2,
  } = expand1();

  const {
    getCollapseProps: getCollapseProps4,
    getToggleProps: getToggleProps4,
    isExpanded: isExpanded4,
  } = expand3();

  const {
    getCollapseProps: getCollapseProps3,
    getToggleProps: getToggleProps3,
    isExpanded: isExpanded3,
  } = expand2();

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Header />
      <div className="Deep_Dive" style={{ marginTop: "50px" }}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center main_div_deep_dive_heading">
            <div className="first_heading_deep">
              <div className="Text_Top_Deep"></div>
              <div className="text_on_Deep_bg">
                <h6>
                  <Link
                    to={back || "/"}
                    onClick={() => {
                      setGroupingArticle(null);
                    }}
                  >
                    <img src={chevronRight} alt="chevronRight" className="me-2 " />
                  </Link>
                  {groupingArticle != null ? (
                    <>{groupingArticle?.topic} </>
                  ) : (
                    <>
                      <Skeleton.Input />
                    </>
                  )}
                  <p className="text_deep">Deep Dive</p>
                </h6>
              </div>
            </div>
            <img
              src={shareIcon}
              alt="shareIcon"
              width="2%"
              height="1%"
              className="mt-5 shareIcon"
              onClick={() => copyToCliboard()}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className="main_Deep_dive">
            <div className="row">
              <div className="col-lg-3">
                <h1
                  className="lister_heading"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setActive(-1);
                  }}
                >
                  Analysis
                </h1>
                <ul className="deep_dive_list">
                  {groupingArticle && groupingArticle.summaries ? (
                    <>
                      {groupingArticle.summaries &&
                        groupingArticle?.summaries
                          .reduce(
                            (acc, curr) =>
                              acc.map((v) => v.title).includes(curr.title)
                                ? acc
                                : [...acc, curr],
                            []
                          )
                          .map((summary, index) => {
                            return (
                              <li
                                className={active === index ? "Active_Item" : ""}
                                onClick={() => setActive(index)}
                              >
                                {summary.title}
                              </li>
                            );
                          })}
                    </>
                  ) : (
                    <>
                      <Skeleton paragraph={{ rows: 4 }} />
                    </>
                  )}
                </ul>
                {score && (
                  <Box sx={{mt:matches ? 15 : 0 , mr:matches ? 5 : 0}}>
                  <div className="Predictions mt-5">
                    <span>Influence Score:</span>
                  </div>
                  <div
                    style={{
                      margin: "50px 0",
                      display: !matches ? "flex" : "block",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    >
                    <div
                      style={{
                        border: "1px solid black",
                        borderRadius: 10,
                        padding: 20,
                        width: !matches
                          ? !ResponsiveMatches
                            ? "100%"
                            : "50%"
                            : "100%",
                          }}
                          >
                      <PieChart
                        series={[
                          {
                            data: score
                            ? [
                                  {
                                    id: 0,
                                    value: score["1_day"],
                                    label: "Day",
                                  },
                                  {
                                    id: 1,
                                    value: score["1_week"],
                                    label: "Week",
                                  },
                                  {
                                    id: 2,
                                    value: score["1_month"],
                                    label: "Month",
                                  },
                                ]
                              : [
                                { id: 0, value: 10, label: "Day" },
                                  { id: 1, value: 10, label: "Week" },
                                  { id: 2, value: 10, label: "Month" },
                                ],
                              },
                        ]}
                        height={150}
                      />
                    </div>
                  </div>
                </Box>
                )}
              </div>
              <div className="col-lg-9">
                <div className="deep_dive_second_col">
                  {active === -1 ? (
                    <ul>
                      {/* {groupingArticle?.deep_research ? (
                        Object.keys(groupingArticle.deep_research).map(
                          (heading) => (
                            <>
                              <h6 className="fs-5 fw-bold" style={{ color: "#6b6b6b" }}>
                                {heading}
                              </h6>
                              <p className="articalText">
                                {groupingArticle.deep_research[heading][
                                  Predicted_text
                                ]?.Description ?? "No description available."}
                              </p>
                            </>
                          )
                        )
                      ) : (
                        <Skeleton paragraph={{ rows: 8 }} />
                      )} */}
                    </ul>
                  ) : (
                    <>
                      <h6 className="fs-5 fw-bold" style={{ color: "#6b6b6b" }}>
                        {groupingArticle.summaries[active].title}
                      </h6>
                      <p className="articalText">
                        {groupingArticle.summaries[active].summary}
                      </p>
                    </>
                  )}
                </div>

                <div className="landing" style={{bottom: active === -1 ? 50 : 0}}>
                  <div className="content " id="At_a_Glance">
                    <div className="content-body" style={{ padding: "0px" }}>
                      <div className="content-right-sider">
                        {EnablePredictions === true ? (
                          <>
                            <div className="Predictions mt-5">
                              <span>Predicted Scenarios:</span>
                              <br />
                              <span className="disclaimer">
                                *<Link to="/Legal_Docs?type=3">Disclaimer</Link>* For
                                educational purposes only
                              </span>
                            </div>
                            <div className="Predictions">
                              <div className="boxes1">
                                <div
                                  className="box1"
                                  style={{
                                    backgroundColor:
                                      isuser == true || Predicted_error == false
                                        ? ""
                                        : "#FFE6E6",
                                    border:
                                      isuser == true || Predicted_error == false
                                        ? ""
                                        : "1px solid rgba(232, 3, 112, 0.8)",
                                  }}
                                >
                                  <h3 className="headerbox text-center pb-3">
                                    {Predicted_text}
                                  </h3>
                                  <ul className="boxInner">
                                    <li className="pb-4">
                                      {groupingArticle ? (
                                        Object.keys(
                                          groupingArticle.deep_research
                                        ).map((heading) => (
                                          <>
                                            {groupingArticle?.deep_research[
                                              heading
                                            ][Predicted_text]?.Description ??
                                              "No description available."}
                                          </>
                                        ))
                                      ) : (
                                        <>
                                          <Skeleton paragraph={{ rows: 4 }} />
                                        </>
                                      )}
                                    </li>

                                    <section {...getCollapseProps5()}>
                                      <li className="pb-3">
                                        {groupingArticle &&
                                        groupingArticle?.deep_research ? (
                                          <>
                                            {
                                              groupingArticle?.deep_research[
                                                "1 day timeframe"
                                              ][Predicted_text]?.Explanation
                                            }
                                          </>
                                        ) : (
                                          <>
                                            <Skeleton paragraph={{ rows: 4 }} />
                                          </>
                                        )}
                                      </li>
                                    </section>
                                  </ul>
                                  <div style={{ textAlign: "center" }}>
                                    <span
                                      {...getToggleProps5()}
                                      className="expand"
                                    >
                                      {isExpanded5 ? "Collapse-" : "Expand+"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3 d-flex justify-content-start">
                                {predCategories
                                  .filter((pca) => pca !== Predicted_text)
                                  .map((pca, index) => {
                                    let classNm = "";
                                    if (index == 0) {
                                      classNm = "me-2";
                                    }
                                    return (
                                      <button
                                        className={`Possible_btn ${classNm}`}
                                        onClick={() => setPredicted_text(pca)}
                                      >
                                        {pca}
                                      </button>
                                    );
                                  })}
                              </div>
                            </div>

                            {isuser == true || Predicted_error == false ? (
                              <></>
                            ) : (
                              <>
                                <Link
                                  to="/signup"
                                  className="link_dec"
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  <div
                                    className="d-flex justify-content-center  align-items-center mt-3"
                                    data-aos="fade-down"
                                    data-aos-easing="linear"
                                    data-aos-duration="500"
                                  >
                                    <div className="d-flex justify-content-center  align-items-center Show_Err0r_ISAuth ">
                                      <ImBlocked className="Auth_icon" />
                                      <p className="Auth_p">
                                        Create a FREE Account to access this
                                        feature!
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Comment Section */}
          <div className="comment-section mt-4">
      <h5>Comments</h5>
      <div>
  {comments?.length > 0 ? (
    comments.map((comment) => (
      <div key={comment._id} className="border border-red-300 p-1 rounded mt-1">
        <div className="text-sm text-gray-600  flex justify-between gap-5 w-full">
          <span className="font-medium ">{comment.commentedBy}</span> 
          <span className="ml-2">
            {new Date(comment.createdAt).toLocaleDateString()}
          </span>
        </div>
        <p className="text-gray-800">{comment.comment}</p>
      </div>
    ))
  ) : (
    <p className="text-gray-600">No comments available.</p>
  )}
</div>


     

      {/* Form for submitting comments */}
      <Form
        className="add-comment mt-3"
        layout="vertical"
        onFinish={handleCommentSubmit} // Trigger handleCommentSubmit on form submission
      >
        <Form.Item
          label="Add a comment"
          name="comment"
          rules={[{ required: true, message: "Please enter a comment" }]}
        >
          <TextArea rows={3} placeholder="Enter your comment" />
        </Form.Item>

        <Upload
          onChange={handleImageUpload}
          showUploadList={false}
          className="mt-2"
        >
          <Button>Upload Image</Button>
        </Upload>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="mt-2"
          >
            Submit Comment
          </Button>
        </Form.Item>
      </Form>
    </div>
        </div>
      </div>
    </>
  );
}
